import React from "react"
import PropTypes from "prop-types"
import OnlineCourseCard from "./Card/OnlineCourseCard"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation as Navigate } from "swiper"
import "swiper/css/navigation"
import "swiper/css"
import Navigation from "./Navigation"

const OnlineCourse = ({ data }) => {
  const onlineCourses = data.map(
    (
      { title, topic, level, slug, minute, image: { gatsbyImageData } },
      idx
    ) => (
      <SwiperSlide key={`column-${idx}`}>
        <OnlineCourseCard
          title={title}
          topic={topic}
          level={level}
          slug={slug}
          minute={minute}
          image={gatsbyImageData}
        />
      </SwiperSlide>
    )
  )

  return (
    <div className="pt-16">
      <div className="wrapper flex justify-between items-center mb-8 sm:mb-12">
        <h2 className="text-3xl tracking-wide font-bold text-neutral-800 sm:text-5xl">
          找線上課
        </h2>
        <Navigation slug="online-course" text="全部線上課" />
      </div>
      <div className="lg:container lg:mx-auto lg:px-8">
        <Swiper
          slidesPerView={1.5}
          centeredSlides={true}
          spaceBetween={24}
          grabCursor={true}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Navigate]}
          lazy={true}
          breakpoints={{
            640: {
              slidesPerView: 1.8,
              spaceBetween: 32,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
          }}
        >
          {onlineCourses}
        </Swiper>
      </div>
    </div>
  )
}

OnlineCourse.propTypes = {
  data: PropTypes.array,
}

export default OnlineCourse
