import React from "react"
import PropTypes from "prop-types"
import Link from "../Link"
import { ChevronRightIcon } from "@heroicons/react/solid"
import { GatsbyImage } from "gatsby-plugin-image"

const EnterpriseCaseCard = ({
  name,
  excerpt,
  postSlug,
  image,
  gatsbyImageData,
}) => {
  return (
    <Link
      to={postSlug}
      style="border border-neutral-400 border-b-4 border-b-amber-400 ease-in-out duration-300 hover:border-b-amber-500"
    >
      {gatsbyImageData && (
        <GatsbyImage
          image={gatsbyImageData}
          alt={name}
          className="block w-full aspect-video object-cover"
        />
      )}
      {image && (
        <img
          src={image}
          alt={name}
          className="block w-full aspect-video object-cover"
        />
      )}

      <div className="p-3 sm:py-4 sm:px-6">
        {/* Todo 解決首頁 Swiper 導致字行數不同的跑版問題(三行隱藏)，目前暫時採用標題兩行隱藏解決此問題 */}
        <p className="text-2xl !leading-normal line-clamp-2 font-bold text-neutral-800 mt-3 mb-3 sm:text-3xl">
          {name}
        </p>
        <p className="!leading-normal text-neutral-600 line-clamp-2 sm:text-lg sm:line-clamp-3">
          {excerpt}
        </p>
        <div className="flex items-center mt-3">
          <p className="text-amber-400 sm:text-lg">繼續閱讀</p>
          <ChevronRightIcon className="h-6 text-amber-400 sm:h-8" />
        </div>
      </div>
    </Link>
  )
}

EnterpriseCaseCard.propTypes = {
  name: PropTypes.string,
  postSlug: PropTypes.string,
  excerpt: PropTypes.string,
  image: PropTypes.string,
  gatsbyImageData: PropTypes.any,
}

export default EnterpriseCaseCard
