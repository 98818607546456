import React from "react"
import PropTypes from "prop-types"
import Link from "./Link"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation as Navigate } from "swiper"
import { GatsbyImage } from "gatsby-plugin-image"
import "swiper/css/navigation"
import "swiper/css"
import Navigation from "./Navigation"

const TeacherBlock = ({ name, slug, type, gatsbyImageData }) => (
  <Link to={`/teacher/${slug}`} style="w-36 text-center group sm:w-44">
    <GatsbyImage
      image={gatsbyImageData}
      alt={name}
      className="block -z-10 rounded-full h-36 w-full sm:h-44"
    />
    <p className="text-2xl !leading-normal text-center font-bold text-neutral-800 mt-3 mb-1 ease-in-out duration-300 group-hover:text-amber-400 sm:text-4xl">
      {name}
    </p>
    <p className="!leading-normal text-center text-neutral-600 sm:text-2xl">
      {type}
    </p>
  </Link>
)

const FindTeacher = ({ data }) => {
  const teachers = data.map(
    ({ id, name, slug, type, image: { gatsbyImageData } }) => (
      <SwiperSlide key={`column-${id}`}>
        <TeacherBlock
          name={name}
          slug={slug}
          type={type}
          gatsbyImageData={gatsbyImageData}
        />
      </SwiperSlide>
    )
  )

  return (
    <div className="pb-16">
      <div className="wrapper flex justify-between items-center mb-8 sm:mb-12">
        <h2 className="text-3xl tracking-wide font-bold text-neutral-800 sm:text-5xl">
          找講師
        </h2>
        <Navigation slug="teacher" text="全部講師" />
      </div>
      <div className="lg:container lg:mx-auto lg:px-8">
        <Swiper
          slidesPerView={2}
          centeredSlides={true}
          spaceBetween={24}
          grabCursor={true}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Navigate]}
          lazy={true}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 64,
              centeredSlides: false,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 32,
              centeredSlides: false,
            },
          }}
        >
          {teachers}
        </Swiper>
      </div>
    </div>
  )
}

TeacherBlock.propTypes = {
  name: PropTypes.string,
  slug: PropTypes.string,
  type: PropTypes.string,
  gatsbyImageData: PropTypes.object,
}

FindTeacher.propTypes = {
  data: PropTypes.array,
}

export default FindTeacher
