import React from "react"
import Button from "./Button"
import PropTypes from "prop-types"

const LearningCircle = ({ description }) => (
  <div className="bg-neutral-700">
    <div className="wrapper flex flex-col items-center text-white py-8 sm:py-16">
      <h2 className="text-3xl font-bold mb-2 sm:text-5xl sm:leading-normal sm:mb-4">
        學習圈
      </h2>
      <p className="mb-6 text-center sm:text-xl sm:mb-8 lg:max-w-5xl">
        {description}
      </p>
      <Button to="/enterprise-case/learning-cycle" size="sm">
        了解更多
      </Button>
    </div>
  </div>
)

LearningCircle.propTypes = {
  description: PropTypes.string,
}

export default LearningCircle
