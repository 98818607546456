import React from "react"
import PropTypes from "prop-types"
import BasicCard from "./Card/BasicCard"
import Navigation from "./Navigation"

const PopularPost = ({ data }) => {
  const popularPosts = data.map((post, idx) => (
    <div key={idx} className="border-t border-t-neutral-400 pt-9">
      <BasicCard data={post} />
    </div>
  ))

  return (
    <div className="wrapper pb-24 sm:pb-36">
      <div className="flex justify-between items-center mb-8 sm:mb-12">
        <h2 className="text-3xl tracking-wide font-bold text-neutral-800 sm:text-5xl">
          熱門文章
        </h2>
        <Navigation slug="training-news" text="全部文章" />
      </div>
      <div className="grid gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-16 lg:grid-cols-3 lg:gap-x-16">
        {popularPosts}
      </div>
    </div>
  )
}

PopularPost.propTypes = {
  data: PropTypes.array,
}

export default PopularPost
