import React, { useState } from "react"
import PropTypes from "prop-types"
import useHover from "../hooks/useHover"
import CourseCard from "./Card/CourseCard"
import Navigation from "./Navigation"

const FilterButton = ({
  name,
  value,
  isCurrentButton,
  setFilterButtonName,
}) => {
  const [hoverRef, isHovered] = useHover()

  return (
    <button
      value={value}
      ref={hoverRef}
      onClick={e => {
        setFilterButtonName(e.currentTarget.value)
      }}
      className="relative"
      type="button"
    >
      <p className="text-lg !leading-normal tracking-wide text-neutral-800 pr-6 sm:pr-8 sm:text-2xl">
        {name}
      </p>
      <div
        className={`absolute w-[74px] left-0 top-8 sm:w-[97px] sm:top-10 ${
          isHovered || isCurrentButton
            ? "border-t-4 border-amber-400"
            : "border-transparent"
        }`}
      />
    </button>
  )
}

const FindCourse = ({ data }) => {
  const [filterButtonName, setFilterButtonName] = useState("latestCourses")

  const filterButtonInfo = [
    { name: "最新上架", value: "latestCourses" },
    { name: "小編推薦", value: "recommendedCourses" },
    { name: "熱門點播", value: "popularCourses" },
  ]

  const filterButtons = filterButtonInfo.map(({ name, value }, idx) => (
    <FilterButton
      name={name}
      value={value}
      key={idx}
      isCurrentButton={filterButtonName === value}
      setFilterButtonName={setFilterButtonName}
    />
  ))

  const filterCourses = data[filterButtonName]

  const courseCards = filterCourses.map(
    (
      {
        title,
        description: { description },
        topic,
        level,
        category,
        slug,
        hours,
        numberOfPeople,
      },
      idx
    ) => (
      <CourseCard
        key={idx}
        title={title}
        description={description}
        topic={topic}
        level={level}
        category={category}
        slug={slug}
        hours={hours}
        numberOfPeople={numberOfPeople}
      />
    )
  )

  return (
    <div className="wrapper pb-24 pt-24 sm:pt-36">
      <div className="flex justify-between items-center mb-8 sm:mb-12">
        <h2 className="text-3xl tracking-wide font-bold text-neutral-800 sm:text-5xl">
          找課程
        </h2>
        <Navigation slug="course" text="全部課程" />
      </div>
      {filterButtons}
      <div className="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 sm:gap-8 sm:mt-8">
        {courseCards}
      </div>
    </div>
  )
}

FilterButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  isCurrentButton: PropTypes.bool,
  setFilterButtonName: PropTypes.func,
}

FindCourse.propTypes = {
  data: PropTypes.object,
}

export default FindCourse
