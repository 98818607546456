import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const OurAdvantageCard = ({ item }) => {
  const {
    title,
    excerpt,
    image: { title: alt, gatsbyImageData },
  } = item
  return (
    <div className="flex flex-col items-center">
      <GatsbyImage
        image={gatsbyImageData}
        alt={alt}
        className="h-52 w-52 sm:w-full mx-auto lg:h-auto lg:max-w-sm"
      />
      <p className="text-2xl leading-tight text-center font-bold text-neutral-800 pt-1 sm:pt-2 sm:pb-2 lg:text-4xl lg:leading-normal">
        {title}
      </p>
      <p className="text-neutral-600 text-center lg:text-2xl">{excerpt}</p>
    </div>
  )
}

const OurAdvantage = ({ data, description }) => {
  const ourAdvantageBlock = data.map((item, index) => (
    <OurAdvantageCard item={item} key={index} />
  ))

  return (
    <div className="bg-neutral-50">
      <div className="wrapper pb-16">
        <h2 className="pt-8 pb-2 text-3xl leading-tight tracking-wide font-bold text-neutral-800 sm:pt-16 sm:pb-3 sm:text-5xl sm:leading-normal">
          我們專注以下三件事
        </h2>
        <p className="leading-normal tracking-wide text-neutral-600 text-neutral-600 pb-8 sm:text-xl sm:pb-12 lg:max-w-5xl">
          {description}
        </p>
        <div className="grid grid-cols-1 gap-10 justify-items-center sm:grid-cols-3 sm:gap-8">
          {ourAdvantageBlock}
        </div>
      </div>
    </div>
  )
}

OurAdvantageCard.propTypes = {
  item: PropTypes.object,
}
OurAdvantage.propTypes = {
  data: PropTypes.array,
  description: PropTypes.string,
}

export default OurAdvantage
