import React from "react"
import Button from "./Button"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { useModalUpdate } from "../contexts/ModalContext"

const Header = ({ title, excerpt, gatsbyImageData }) => {
  const setIsModal = useModalUpdate()

  return (
    <div className="wrapper lg:grid lg:grid-cols-2 lg:gap-16 lg:items-center">
      <div>
        <h1 className="text-4xl !leading-tight tracking-wide font-bold text-neutral-800 mt-12 mb-2 sm:text-6xl sm:mb-3">
          {title}
        </h1>
        <p className="!leading-tight tracking-wide text-neutral-600 mb-6 sm:text-2xl sm:mb-8">
          {excerpt}
        </p>
        <Button isLink={false} onClick={setIsModal}>
          立即線上諮詢
        </Button>
      </div>
      <GatsbyImage
        image={gatsbyImageData}
        alt="bg image"
        className="w-full mt-12 sm:mt-16 lg:mt-12"
      />
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  gatsbyImageData: PropTypes.object,
}

export default Header
