import React from "react"
import PropTypes from "prop-types"
import EnterpriseCaseCard from "./Card/EnterpriseCaseCard"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation as Navigate } from "swiper"
import "swiper/css/navigation"
import "swiper/css"
import Navigation from "./Navigation"

const EnterpriseCase = ({ data }) => {
  const enterpriseCases = data.map(
    ({
      id,
      name,
      excerpt: { excerpt },
      slug,
      image: { gatsbyImageData },
      topic: { slug: topicSlug },
    }) => {
      const postSlug = `/${topicSlug}/${slug}`

      return (
        <SwiperSlide key={`column-${id}`}>
          <EnterpriseCaseCard
            name={name}
            excerpt={excerpt}
            postSlug={postSlug}
            gatsbyImageData={gatsbyImageData}
          />
        </SwiperSlide>
      )
    }
  )

  return (
    <div className="py-24 sm:py-36">
      <div className="wrapper flex justify-between items-center mb-8 sm:mb-12">
        <h2 className="text-3xl tracking-wide font-bold text-neutral-800 sm:text-5xl">
          企業案例
        </h2>
        <Navigation slug="enterprise-case" text="全部案例" />
      </div>
      <div className="lg:container lg:mx-auto lg:px-10">
        <Swiper
          slidesPerView={1.5}
          centeredSlides={true}
          spaceBetween={24}
          grabCursor={true}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Navigate]}
          lazy={true}
          breakpoints={{
            640: {
              slidesPerView: 1.8,
              spaceBetween: 32,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
          }}
        >
          {enterpriseCases}
        </Swiper>
      </div>
    </div>
  )
}

EnterpriseCase.propTypes = {
  data: PropTypes.array,
}

export default EnterpriseCase
