import React from "react"
import Layout from "../components/layout"
import CoursePlanning from "../components/CoursePlanning"
import OurAdvantage from "../components/OurAdvantage"
import Enterprise from "../components/Enterprise"
import Result from "../components/Result"
import { useLandingPageData } from "../data/useLandingPageData"
import Header from "../components/Header"
import LearningCircle from "../components/LearningCircle"
import Consult from "../components/Consult"
import Problem from "../components/Problem"
import PopularPost from "../components/PopularPost"
import FindTeacher from "../components/FindTeacher"
import OnlineCourse from "../components/OnlineCourse"
import EnterpriseCase from "../components/EnterpriseCase"
import FindCourse from "../components/FindCourse"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

const IndexPage = ({ data }) => {
  const {
    description: { description },
    title,
    excerpt,
    gatsbyImageData,
    coursePlanning,
    ourAdvantage,
    problem: problems,
    learningCircle,
    enterprise,
    result,
    consultTitle,
    consultGatsbyImageData,
    teacher: teachers,
    onlineCourse: onlineCourses,
  } = useLandingPageData()

  const {
    allContentfulCourse: { nodes: latestCourses },
    popularCourses: { nodes: popularCourses },
    recommendedCourses: { nodes: recommendedCourses },
    enterpriseCases: { nodes: enterpriseCases },
    popularPosts: { nodes: popularPosts },
  } = data

  const courses = {
    latestCourses,
    popularCourses,
    recommendedCourses,
  }

  return (
    <Layout showTitleTemplate={false}>
      <Header
        title={title}
        excerpt={excerpt}
        gatsbyImageData={gatsbyImageData}
      />
      <div className="pb-16 sm:pb-24">
        <CoursePlanning data={coursePlanning} />
      </div>
      <OurAdvantage data={ourAdvantage} description={description} />
      <Problem data={problems} />
      <Enterprise data={enterprise} />
      <FindCourse data={courses} />
      <FindTeacher data={teachers} />
      <LearningCircle description={learningCircle} />
      <OnlineCourse data={onlineCourses} />
      <EnterpriseCase data={enterpriseCases} />
      <PopularPost data={popularPosts} />
      <Result data={result} />
      <Consult
        consultTitle={consultTitle}
        gatsbyImageData={consultGatsbyImageData}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
}

export default IndexPage

export const pageQuery = graphql`
  {
    allContentfulCourse(limit: 4, sort: { fields: updatedAt, order: DESC }) {
      nodes {
        ...CourseData
      }
    }
    recommendedCourses: allContentfulCourse(
      limit: 4
      sort: { fields: updatedAt, order: DESC }
      filter: { isRecommended: { eq: true } }
    ) {
      nodes {
        ...CourseData
      }
    }
    popularCourses: allContentfulCourse(
      limit: 4
      sort: { fields: updatedAt, order: DESC }
      filter: { isPopular: { eq: true } }
    ) {
      nodes {
        ...CourseData
      }
    }
    enterpriseCases: allContentfulPost(
      limit: 6
      filter: { topic: { name: { eq: "企業案例" } } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        id
        slug
        name
        excerpt {
          excerpt
        }
        image {
          gatsbyImageData(
            width: 450
            height: 248
            placeholder: BLURRED
            quality: 100
          )
        }
        topic {
          slug
        }
      }
    }
    popularPosts: allContentfulPost(
      limit: 6
      filter: {
        isPopular: { eq: true }
        topic: { slug: { eq: "training-news" } }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        id
        slug
        name
        updatedAt(formatString: "YYYY-MM-DD")
        excerpt {
          excerpt
        }
        topic {
          name
          slug
        }
      }
    }
  }
  fragment CourseData on ContentfulCourse {
    topic {
      id
      name
    }
    level {
      id
      name
    }
    category {
      id
      name
    }
    description {
      description
    }
    hours
    contentful_id
    isPopular
    isRecommended
    id
    title
    slug
    numberOfPeople
  }
`
