import React from "react"
import PropTypes from "prop-types"
import { ChevronRightIcon } from "@heroicons/react/solid"
import Button from "./Button"
import { StaticImage } from "gatsby-plugin-image"
import Link from "./Link"
import { useModalUpdate } from "../contexts/ModalContext"

const ProblemCard = ({ title, excerpt, url, setIsModal }) => (
  <li>
    <h2 className="text-2xl font-bold text-neutral-800 mt-5 sm:text-3xl">
      {title}
    </h2>
    <p className="text-neutral-600 mt-1 mb-2 sm:text-lg">{excerpt}</p>
    {url.includes("lin.ee") ? (
      <button className="flex items-center mb-5 group" onClick={setIsModal}>
        <p className="text-amber-400 ease-in-out duration-300 group-hover:text-amber-500 sm:text-lg">
          了解更多
        </p>
        <ChevronRightIcon className="h-6 text-amber-400 group-hover:text-amber-500 sm:h-8" />
      </button>
    ) : (
      <Link
        to={url}
        style="flex items-center mb-5 group"
        as={url.includes("http") ? "a" : "link"}
      >
        <p className="text-amber-400 ease-in-out duration-300 group-hover:text-amber-500 sm:text-lg">
          了解更多
        </p>
        <ChevronRightIcon className="h-6 text-amber-400 group-hover:text-amber-500 sm:h-8" />
      </Link>
    )}
  </li>
)

const Problem = ({ data }) => {
  const setIsModal = useModalUpdate()

  const problemCards = data.map(({ title, excerpt, url }, idx) => (
    <ProblemCard
      title={title}
      excerpt={excerpt}
      url={url}
      key={idx}
      setIsModal={setIsModal}
    />
  ))

  return (
    <div className="wrapper">
      <h2 className="text-3xl leading-tight tracking-wide font-bold text-neutral-800 mt-16 sm:mt-24 sm:text-5xl lg:mb-8">
        如果您遇到以下這些問題
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <StaticImage
          src="../images/img_problems_M-T.png"
          alt="mobile problem image"
          className="w-full my-6 sm:my-8 lg:!hidden"
          width={706}
          height={398}
          quality={100}
        />
        <ul className="divide-y divide-neutral-400 lg:my-4 lg:self-center">
          {problemCards}
        </ul>
        <StaticImage
          src="../images/img_problems_D.png"
          alt="desktop problem image"
          className="!hidden w-full lg:!block lg:max-w-lg lg:justify-self-center lg:self-center lg:h-max"
          width={512}
          height={640}
          quality={100}
        />
      </div>
      <div className="flex justify-center mt-3 lg:mt-8">
        <Button isLink={false} onClick={setIsModal}>
          立即諮詢
        </Button>
      </div>
    </div>
  )
}

Problem.propTypes = {
  data: PropTypes.array,
}

ProblemCard.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  url: PropTypes.string,
  setIsModal: PropTypes.func,
}

export default Problem
