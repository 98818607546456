import React from "react"
import PropTypes from "prop-types"
import { ChevronRightIcon } from "@heroicons/react/solid"
import Link from "./Link"

const Navigation = ({ slug, text }) => {
  return (
    <Link to={`/${slug}`}>
      <div className="flex justify-center items-center flex-nowrap translate-x-3 group">
        <p className="ease-in-out duration-300 text-lg text-neutral-800 group-hover:text-amber-500 sm:text-2xl">
          {text}
        </p>
        <ChevronRightIcon className="ease-in-out duration-300 h-6 text-yellow-500 ml-1 group-hover:text-amber-500 sm:h-8" />
      </div>
    </Link>
  )
}

Navigation.propTypes = {
  slug: PropTypes.string,
  text: PropTypes.string,
}

export default Navigation
