import React from "react"
import PropTypes from "prop-types"
import Link from "../Link"
import ContinueReading from "./ContinueReading"
import CategoryAndUpdateTime from "./CategoryAndUpdateTime"

const BasicCard = ({ data }) => {
  const {
    topic: { slug: topicSlug, name: topicName },
    excerpt: { excerpt },
    name,
    slug,
    updatedAt,
  } = data

  const postSlug = `/${topicSlug}/${slug}`

  return (
    <div className="space-y-4">
      <CategoryAndUpdateTime
        topicSlug={topicSlug}
        topicName={topicName}
        updatedAt={updatedAt}
      />
      <div className="space-y-4">
        <div className="divide-y divide-neutral-400 pb-4 group">
          <Link to={`/${topicSlug}/${slug}`}>
            <p className="text-2xl tracking-wide !leading-normal text-neutral-800 line-clamp-3 font-bold group-hover:underline group-hover:underline-offset-4 sm:text-3xl">
              {name}
            </p>
            <p className="text-neutral-600 line-clamp-3 pt-4 sm:text-lg">
              {excerpt}
            </p>
          </Link>
        </div>
        <ContinueReading postSlug={postSlug} />
      </div>
    </div>
  )
}

BasicCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BasicCard
