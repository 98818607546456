import React from "react"
import PropTypes from "prop-types"

const Enterprise = ({ data }) => {
  const enterpriseBlock = data.map(({ title, image: { url } }, idx) => (
    <img
      key={idx}
      src={url}
      alt={title}
      className="h-6 object-contain w-full grayscale transition duration-300 hover:grayscale-0 sm:h-12"
    />
  ))

  return (
    <div className="wrapper">
      <h2 className="text-3xl text-neutral-800 font-bold tracking-wide mt-24 mb-8 sm:text-5xl sm:mt-36 sm:mb-12">
        知名企業的共同選擇
      </h2>
      <div className="grid grid-cols-3 gap-x-4 gap-y-6 sm:gap-x-12 sm:gap-y-12 lg:grid-cols-5">
        {enterpriseBlock}
      </div>
    </div>
  )
}

Enterprise.propTypes = {
  data: PropTypes.array,
}

export default Enterprise
